import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import { apicallback } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
import { Bars } from 'react-loader-spinner';
import { IoCloseSharp } from '../../components/icons/Index';
const Articles = () => {
  const location = useLocation();
  const [list, setList] = useState([]);
  const [last_updated, setLastUpdate] = useState('');
  const [loader, setLoader] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loadMore, setLoaderMore] = useState(false);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(true);
  useEffect(() => {
    getTopCollections(
      location.state && location.state.tag ? location.state.tag : ''
    );
  }, []);

  const getTopCollections = async (tag, keyword = '') => {
    const response = await apicallback(
      'content',
      '/articles',
      {
        tag_id: tag,
        offset: 0,
        keyword: keyword,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      setList(response.data);
      setOffset(response.offset);
      setLoader(false);
      setTotalCount(response.totalCount);
      setLastUpdate(response.last_updated);
    }
  };

  const getLoadMore = async (tag) => {
    const response = await apicallback(
      'content',
      '/articles',
      {
        tag_id: tag,
        offset: offset,
        keyword: search,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      setList([...list, ...response.data]);
      setOffset(response.offset);
      setTotalCount(response.totalCount);
    }
    setLoader(false);
  };

  const handelSearch = (value) => {
    setLoader(true);
    getTopCollections(
      location.state && location.state.tag ? location.state.tag : '',
      value
    );
  };

  return (
    <section className="tf-section tf-rank">
      <PageTitle
        meta={{
          title: 'Your Trusted Source for Web3 & NFT News Alerts | ANZALI',
          description:
            'Stay up to date with the latest news in Web3, NFT, and blockchain. Receive daily insights with curated articles. Short articles are easy to digest.',
        }}
      />
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions mg-bt-21 d-inline d-sm-flex">
              <div>
                <h2 className="text-left  mb-2">News</h2>
                {list.length > 0 ? (
                  <p className="h4 mt-0 mb-5 text-main cursor-pointer">
                    Last updated {last_updated}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div className="position-relative mt-2 mt-sm-3 col-md-3 col-sm-5 col-12 mb-5 mb-sm-0 px-0 search-container">
                {search ? (
                  <span>
                    <IoCloseSharp
                      onClick={(e) => {
                        handelSearch('');
                        setSearch('');
                      }}
                    />
                  </span>
                ) : (
                  ''
                )}
                <input
                  onChange={(e) => {
                    handelSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                  placeholder="Search…"
                  className="comman-input float-none float-sm-right"
                  value={search}
                />
              </div>
            </div>
          </div>
          {list.length > 0 ? (
            <>
              {list.map((item, index) => (
                <div
                  key={index}
                  className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                >
                  <div className={`sc-card-product`}>
                    <div className="card-media">
                      <Link
                        to={`/news/${item.slug ? item.slug : item.article_id}`}
                        state={{
                          obj: item,
                          sliced: list.slice(0, 5),
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.title ? `${item.title}` : `#`}
                        />
                      </Link>
                    </div>
                    <div className="card-">
                      <h5 className="style3 mb-4">
                        <Link
                          to={`/news/${
                            item.slug ? item.slug : item.article_id
                          }`}
                          state={{
                            obj: item,
                            sliced: list.slice(0, 5),
                          }}
                        >
                          {item.title ? `${item.title}` : `#`}
                        </Link>
                      </h5>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="info">
                          <span>Posted</span>
                          <h6>
                            <Link to="#">{item.date ? item.date : '-'}</Link>
                          </h6>
                        </div>
                      </div>
                      <div className="price">
                        <span>Author</span>
                        <h6>
                          <Link to="#">{item.author}</Link>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : loader ? (
            <InstagramLoader value={4} coloum={3} />
          ) : (
            <h4 className="mt-5 text-center w-100">
              No articles to see at the moment.
            </h4>
          )}
        </div>
        <div className="text-center">
          {totalCount > 12 && totalCount > offset ? (
            <button
              onClick={() =>
                getLoadMore(
                  location.state && location.state.tag ? location.state.tag : ''
                )
              }
              className="sc-button style-4"
            >
              {loadMore ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  );
};

export default Articles;
