import PageTitle from '../../components/pagetitle/PageTitle';
import React, { useState, useEffect } from 'react';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import NewsCard from '../../components/card/NewsCard';
import { apicallback } from '../../callbacks/index';
import { Bars } from 'react-loader-spinner';
export default function Blogs() {
  const [blogs, setBlogs] = useState(null);
  const [isBlogLoading, setIsBlogLoading] = useState(true);
  useEffect(() => {
    getBlogs();
  }, []);

  async function getBlogs() {
    const response = await apicallback(
      'content',
      '/blogs',
      {
        offset: 0,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      setBlogs(response);
      setIsBlogLoading(false);
    }
  }
  return (
    <section className="tf-section tf-rank">
      <PageTitle
        meta={{
          title: 'Web3 and NFT Articles and Information | ANZALI',
          description:
            'Dive into the fascinating world of Web3 and NFTs with our insightful blogs. Stay updated on the latest trends, innovations, and insights in the blockchain universe.',
        }}
      />
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="heading-live-auctions mg-bt-21 d-inline d-sm-flex">
              <div>
                <h2 className="text-left  mb-2">Blog</h2>
              </div>
            </div>
          </div>
          {isBlogLoading === false ? (
            Array.isArray(blogs.data) && blogs.data.length ? (
              blogs.data.map((value, index) => (
                <div
                  key={index}
                  className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex align-items-stretch mb-4"
                >
                  <NewsCard
                    title={value.title}
                    image={value.image}
                    type="Blog"
                    date={value.date_time}
                    url={`/blog/${value.slug}`}
                    author={value.author}
                  />
                </div>
              ))
            ) : (
              ''
            )
          ) : (
            <InstagramLoader value={4} coloum={3} />
          )}
        </div>
      </div>
    </section>
  );
}
