import '../../assets/main/scss/component/_news-card.scss';
import { useNavigate } from 'react-router-dom';
export default function NewsCard(props) {
  const { title, image, type, date, url, author } = props;
  const history = useNavigate();
  function handelRedirection(url) {
    history(url);
  }
  return (
    <div className="_news_card">
      <div className="image" onClick={() => handelRedirection(url)}>
        <img src={image} alt={title} />
      </div>
      <div className="body p-4" onClick={() => handelRedirection(url)}>
        <h4>{title}</h4>
        <div className="d-flex align-items-center justify-content-between mt-4">
          <p>{type}</p>
          <span>{date}</span>
        </div>
      </div>
    </div>
  );
}
